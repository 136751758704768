import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_SideContent = _resolveComponent("SideContent")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutTwoColumn = _resolveComponent("LayoutTwoColumn")!

  return (_openBlock(), _createBlock(_component_LayoutTwoColumn, null, {
    side: _withCtx(() => [
      _createVNode(_component_SideContent, null, {
        default: _withCtx(() => [
          _createVNode(_component_SideMenu)
        ]),
        _: 1
      })
    ]),
    main: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_component_Header, { class: "mb-7" }),
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}