
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useWorkspace } from '@/composables/workspace';
import SideMenu from '@/pages/workspace/SideMenu.vue';
import LayoutTwoColumn from '@/components/layout/LayoutTwoColumn.vue';
import SideContent from '@/components/layout/SideContent.vue';
import Header from '@/pages/workspace/Header.vue';
import Modal from '@/components/ui/Modal.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'WorkspaceTop',
  components: {
    Header,
    Modal,
    LayoutTwoColumn,
    SideContent,
    SideMenu,
    Typography
  },
  setup() {
    const route = useRoute();
    const workspaceId = route.params.workspaceId as string;
    const { getWorkspace } = useWorkspace();
    getWorkspace(workspaceId);
  }
});
