<template>
  <Flex flex-direction="column" height="100%" class="workspace-side-menu">
    <Flex justify-center>
      <WorkspaceLogo :src="workspaceImage" size="l" />
    </Flex>
    <Modal v-model="isWorkspaceImageModalOpen">
      <UploadImage
        :src="cropTargetImage"
        :loading="isPutImageLoading"
        @cropped="onCroppedImage"
      />
      <template v-slot:activator>
        <Flex v-if="acl.can(Rules.owner)" justify-center class="mt-1">
          <Button
            v-if="workspaceImage"
            variant="text"
            size="xs"
            @click="changeWorkspaceImage"
          >
            画像を変更
          </Button>
          <UploadTextButton v-else @change-file="onLoadImage" />
        </Flex>
      </template>
    </Modal>
    <Modal v-model="isLeaveWorkspaceOpen" title="ワークスペースを退出する">
      <div>
        <Typography text-align="center" line-height="1.5">
          <Typography bold tag="span"
            >{{ `「${workspace.name}」` }}
          </Typography>
          を退出します<br /><br />
          ワークスペースを退出すると<br />
          再び参加することができません。<br />
          退出してよろしいですか？
        </Typography>
        <Flex class="mt-6">
          <Button
            class="mr-2"
            width="140px"
            size="m"
            variant="outlined"
            color="grey"
            @click="closeLeaveWorkspaceModal"
          >
            キャンセル
          </Button>
          <Button
            class="ml-2"
            width="140px"
            color="red"
            size="m"
            @click="onLeaveWorkspace"
          >
            退出する
          </Button>
        </Flex>
      </div>
    </Modal>
    <div class="mt-4 edit-workspace-name" :class="{ editing: isEditing }">
      <TextField
        v-if="acl.can(Rules.owner)"
        dense
        class="edit-form workspace-name-input"
        v-model="workspace.name"
        :error-message="errorMessage"
        @focus="enableEditing"
        @input="validate(workspace.name)"
      />
      <Typography v-else size="s">{{ workspace.name }}</Typography>
      <Icon
        v-if="!isEditing && acl.can(workspaceChangeNameAcl)"
        name="edit"
        sides="12"
        class="edit-icon"
      />
      <Flex v-if="isEditing" justify-end class="mt-2">
        <Button
          icon-only
          icon-name="check"
          color="white"
          class="mr-2 edit-form"
          :loading="isChangeNameLoading"
          :disabled="
            validate(workspace.name) ||
              !acl.can(Rules.owner) ||
              workspace.name.replace(/[\p{C}\p{Z}]/gu, '').length === 0
          "
          @click="onChangeName(workspace)"
        />
        <Button
          icon-only
          icon-name="close"
          color="white"
          class="edit-form"
          @click="onCancelWorkspaceEdit"
        />
      </Flex>
    </div>

    <Box flex="1" class="mt-2">
      <SideContentMenu :menus="menus" />
    </Box>

    <Divider class="mb-1" />
    <Flex
      class="mt-3 mb-1"
      v-for="privilege in privilegeList"
      :key="privilege.id"
      justify-space-between
    >
      <Flex align-center>
        <PrivilegeIcon :type="privilege.id" />
        <Typography class="ml-2">{{ privilege.name }}</Typography>
      </Flex>
      <Typography>{{ privilege.count }}人</Typography>
    </Flex>
    <template v-if="acl.can(Rules.free)">
      <Divider class="mt-3 mb-1" />
      <div class="side-content-menu-item mt-3">
        <Typography line-height="1">
          <span
            class="side-content-menu-label leave-workspace"
            @click="openLeaveWorkspaceModal"
          >
            ワークスペースから退出
          </span>
        </Typography>
      </div>
    </template>
  </Flex>
  <PageLoaing v-if="isPageLoading || isRemoving" />
</template>

<script>
import { defineComponent, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { WORKSPACE_ROUTES, MYPAGE_ROUTES } from '@/router';
import { useRoute, useRouter } from 'vue-router';
import { AclRules } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import { useWorkspaceStore } from '@/store/workspace';
import { useUserInfoStore } from '@/store/userInfo';
import {
  useWorkspaceName,
  useWorkspaceImage,
  useWorkspaceMemberList
} from '@/composables/workspace';
import { useWorkspaceUser } from '@/composables/workspaceUser';
import Flex from '@/components/layout/Flex.vue';
import PrivilegeIcon from '@/components/ui/PrivilegeIcon.vue';
import Box from '@/components/layout/Box.vue';
import Button from '@/components/ui/Button.vue';
import Divider from '@/components/ui/Divider.vue';
import Icon from '@/components/ui/Icon.vue';
import Modal from '@/components/ui/Modal.vue';
import PageLoaing from '@/components/ui/PageLoading.vue';
import SideContentMenu from '@/components/ui/SideContentMenu.vue';
import TextField from '@/components/ui/TextField.vue';
import Typography from '@/components/ui/Typography.vue';
import UploadImage from '@/components/ui/UploadImage.vue';
import UploadTextButton from '@/components/ui/UploadTextButton.vue';
import WorkspaceLogo from '@/components/ui/WorkspaceLogo.vue';
import { companyChangeName, workspaceChangeNameAcl } from '@/composables/acls';
import { successToast, errorToast, toast } from '@/components/ui/Toast/toast';
import { JoinWorkspaceAccountPrivilegeEnum } from '@/api/openapi';
import useModal from '@/composables/modal';
import { WorkspaceApi } from '@/api';

export default defineComponent({
  name: 'WorkspaceSideMenu',
  components: {
    Box,
    Button,
    Divider,
    Icon,
    Flex,
    Modal,
    PageLoaing,
    PrivilegeIcon,
    SideContentMenu,
    TextField,
    Typography,
    UploadImage,
    UploadTextButton,
    WorkspaceLogo
  },
  setup() {
    const acl = useAcl();
    const {
      params: { workspaceId }
    } = useRoute();
    const router = useRouter();

    const store = useWorkspaceStore();
    const { workspace } = storeToRefs(store);

    const userInfoStore = useUserInfoStore();
    const user = userInfoStore.userInfo;

    const {
      workspaceImage,
      cropTargetImage,
      isPutImageLoading,
      isPageLoading,
      isWorkspaceImageModalOpen,
      changeWorkspaceImage,
      onCroppedImage,
      onLoadImage
    } = useWorkspaceImage(workspaceId);

    const {
      isChangeNameLoading,
      changeName,
      isEditing,
      disabledEditing,
      enableEditing,
      validate,
      errorMessage
    } = useWorkspaceName(workspaceId);

    const {
      isModalOpen: isLeaveWorkspaceOpen,
      openModal: openLeaveWorkspaceModal,
      closeModal: closeLeaveWorkspaceModal
    } = useModal();

    const { memberList } = useWorkspaceMemberList(workspaceId);

    const { removeUser, isRemoving } = useWorkspaceUser(workspaceId);

    const getWorkspaceMemberList = async workspaceId => {
      let memberList = {
        owner: 0,
        manager: 0,
        member: 0,
        restricted: 0,
        freeOwner: 0,
        freeMember: 0
      };
      const result = await WorkspaceApi.getWorkspacesWorkspaceIdBasicInfo(
        Number(workspaceId)
      );
      if (result?.data.memberList) {
        memberList = result.data.memberList;
      }
      return memberList;
    };

    const getPaidWorkspaceMemberCount = async () => {
      const result = await getWorkspaceMemberList(workspaceId);
      return result.owner + result.manager + result.member + result.restricted;
    };

    const getFreeWorkspaceMemberCount = async () => {
      const result = await getWorkspaceMemberList(workspaceId);
      return result.freeOwner + result.freeMember;
    };

    const onLeaveWorkspace = async () => {
      try {
        closeLeaveWorkspaceModal();
        const workspace = user.workspaces.find(workspace => {
          return workspace.workspaceId === Number(workspaceId);
        });
        if (!workspace) return;

        const warningToast = () => {
          toast({
            title: 'プライマリーオーナーは退出できません',
            message: '権限を譲渡してから退出してください',
            variant: 'error'
          });
        };

        if (
          workspace.accountPrivilege ===
            JoinWorkspaceAccountPrivilegeEnum.PrimaryOwner &&
          (await getPaidWorkspaceMemberCount()) >= 2
        ) {
          warningToast();
          return;
        }

        if (
          workspace.accountPrivilege ===
            JoinWorkspaceAccountPrivilegeEnum.FreePrimaryOwner &&
          (await getFreeWorkspaceMemberCount()) >= 2
        ) {
          warningToast();
          return;
        }

        await removeUser(user.id);
        successToast();
        await router.push({
          name: MYPAGE_ROUTES.top
        });
      } catch (err) {
        closeLeaveWorkspaceModal();
        errorToast();
      }
    };

    const onCancelWorkspaceEdit = () => {
      workspace.value.name = store.origWorkspace.name;
      disabledEditing();
      errorMessage.value = '';
    };

    const onChangeName = workspace => {
      store.setOrigWorkspace(workspace);
      changeName(workspace.name);
    };

    const privileges = computed(() => {
      return [
        {
          name: 'オーナー',
          id: 'OWNER',
          count: memberList.value.owner
        },
        {
          name: '管理者',
          id: 'MANAGER',
          count: memberList.value.manager
        },
        {
          name: 'メンバー',
          id: 'MEMBER',
          count: memberList.value.member
        },
        {
          name: '制限付き',
          id: 'RESTRICTED',
          count: memberList.value.restricted
        },
        {
          name: 'プライマリー',
          id: 'FREE_PRIMARY_OWNER',
          count: memberList.value.freeOwner
        },
        {
          name: 'メンバー',
          id: 'FREE_MEMBER',
          count: memberList.value.freeMember
        }
      ];
    });

    const privilegeList = computed(() => {
      if (workspace.value.paymentType === 'FREE') {
        return privileges.value.filter(
          ({ id }) => id === 'FREE_PRIMARY_OWNER' || id === 'FREE_MEMBER'
        );
      } else {
        return privileges.value.filter(
          ({ id }) =>
            id === 'OWNER' ||
            id === 'MANAGER' ||
            id === 'MEMBER' ||
            id === 'RESTRICTED'
        );
      }
    });

    return {
      workspace,
      menus: [
        {
          items: [
            {
              label: 'ワークスペース情報',
              toName: WORKSPACE_ROUTES.top,
              iconName: 'info',
              authority: AclRules.all
            },
            {
              label: 'ユーザー管理',
              toName: WORKSPACE_ROUTES.users,
              iconName: 'users',
              authority: AclRules.all
            },
            {
              label: 'アクセス権設定',
              toName: WORKSPACE_ROUTES.access,
              iconName: 'user-check',
              authority: AclRules.privilege
            }
          ]
        }
      ],
      isEditing,
      disabledEditing,
      enableEditing,
      validate,
      errorMessage,
      isChangeNameLoading,
      changeName,
      onCancelWorkspaceEdit,
      onChangeName,
      workspaceImage,
      isWorkspaceImageModalOpen,
      changeWorkspaceImage,
      cropTargetImage,
      onCroppedImage,
      onLoadImage,
      isPutImageLoading,
      isPageLoading,
      acl,
      companyChangeName,
      workspaceChangeNameAcl,
      privilegeList,
      isLeaveWorkspaceOpen,
      closeLeaveWorkspaceModal,
      onLeaveWorkspace,
      isRemoving,
      Rules: AclRules,
      openLeaveWorkspaceModal
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/effect';
@import '@/styles/layout';
@import '@/styles/spacing';

.workspace-side-menu {
  overflow: hidden;
}

.edit-workspace-name {
  position: relative;

  .workspace-name-input {
    width: calc(100% - 4px);
    margin-left: 2px;
  }

  .base-input {
    position: relative;
    background-color: transparent;
    z-index: 2;
    padding-right: 28px;

    &:not(:focus) {
      border-color: transparent;
      @extend .hover-background;

      &:not(:disabled):hover {
        cursor: text;
      }
    }
  }

  .edit-icon {
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 1;
  }

  &.editing {
    .edit-form {
      position: relative;
      z-index: $z-index-clickgurard + 1;
    }

    .base-input {
      padding-right: 8px;
    }
  }
}

.leave-workspace {
  justify-content: center;
}
</style>
