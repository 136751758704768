import { WorkspaceApi } from '@/api';
import useLoading from '@/composables/loading';
import { Ref } from 'vue';

interface UseWorkspaceUser {
  removeUser: (userId: number) => Promise<void>;
  isRemoving: Ref<boolean>;
}

export const useWorkspaceUser = (workspaceId: number): UseWorkspaceUser => {
  const _removeUser = async (userId: number) => {
    const res = await WorkspaceApi.deleteWorkspacesWorkspaceIdAccountsAccountId(
      workspaceId,
      userId
    );
    if (!(200 <= res.status && res.status < 300)) {
      throw new Error('');
    }
  };
  const [isRemoving, removeUser] = useLoading(_removeUser);
  return {
    removeUser,
    isRemoving
  };
};
