<template>
  <Typography size="xl" class="create-company-title">
    {{ `カンパニーの新規作成（${stepCount}/2）` }}
  </Typography>

  <Box width="672px" class="mt-10">
    <template v-if="stepCount === 1">
      <Typography size="l" bold text-align="center">
        CM企業を選択
      </Typography>
      <CreateCompanyStep1
        :company="company"
        @select-company="
          company => {
            setCompanyInfo(company);
            isSelectedCompany = typeof company.id !== 'undefined';
          }
        "
      />
      <Flex justify-center class="mt-9">
        <Button size="l" :disabled="!isSelectedCompany" @click="nextStep">
          次へ
        </Button>
      </Flex>
    </template>

    <template v-if="stepCount === 2">
      <Typography size="l" bold text-align="center">
        カンパニー名の設定
      </Typography>

      <Flex justify-center class="mt-8">
        <Box width="456px">
          <TextField
            v-model="company.name"
            size="m"
            :error-message="errorMessage"
            @focus="validateDuplicateCompany(company.name)"
            @input="debouncedInput"
          />
        </Box>
      </Flex>
      <Flex justify-center class="mt-8">
        <Button
          variant="outlined"
          width="120px"
          size="m"
          class="mx-2"
          @click="backStep"
        >
          戻る
        </Button>
        <Button
          :disabled="errorMessage !== '' || company.name === ''"
          :loading="isPostingCompany"
          type="submit"
          class="mx-2"
          width="120px"
          size="m"
          @click="
            async () => {
              await validateDuplicateCompany(company.name);
              if (errorMessage === '' && company.id && !isPostingCompany) {
                createCompany(company.id).then(() => {
                  if (errorMessage === '') {
                    $emit('closeModal');
                  }
                });
              }
            }
          "
        >
          作成する
        </Button>
      </Flex>
    </template>
  </Box>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { debounce } from 'lodash';
import { useCreateCompany, useValidateCompany } from '@/composables/workspace';
import CreateCompanyStep1 from '@/pages/workspace/CreateCompanyStep1.vue';
import Box from '@/components/layout/Box';
import Flex from '@/components/layout/Flex';
import Button from '@/components/ui/Button';
import TextField from '@/components/ui/TextField.vue';
import Typography from '@/components/ui/Typography.vue';
import { toast } from '@/components/ui/Toast';

export default defineComponent({
  name: 'WorkspaceCreateCompany',
  emits: ['closeModal'],
  components: {
    Box,
    Button,
    CreateCompanyStep1,
    Flex,
    Typography,
    TextField
  },
  setup() {
    const {
      company,
      isPostingCompany,
      createCompany,
      setCompanyInfo
    } = useCreateCompany();

    const {
      errorMessage,
      validateDuplicateCompany,
      resetErrorMessage
    } = useValidateCompany();

    const stepCount = ref(1);
    const nextStep = () => stepCount.value++;
    const backStep = () => {
      stepCount.value--;
      resetErrorMessage();
    };
    const isSelectedCompany = ref(false);
    const debouncedInput = debounce(event => {
      validateDuplicateCompany(event.target.value);
    }, 100);

    return {
      isSelectedCompany,
      errorMessage,
      stepCount,
      createCompany,
      toast,
      nextStep,
      backStep,
      isPostingCompany,
      company,
      setCompanyInfo,
      debouncedInput,
      validateDuplicateCompany
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/layout';

.create-company {
  position: relative;
  &-title {
    position: absolute;
    top: $spacing-base * 6;
  }
  &-datalist {
    border-bottom: 1px solid var(--primary-light-color);
    padding: $spacing-base * 4;
  }
}
</style>
