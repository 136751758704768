<template>
  <Card>
    <Flex justify-space-between align-center>
      <Flex align-center>
        <AccountIcon :image-url="user.accountImage" />
        <Typography class="ml-3">
          {{ user?.nickname || user?.name }}
        </Typography>
        <Typography color="grey" size="s" class="ml-2">
          {{ user?.email }}
        </Typography>
        <PrivilegeIcon
          class="ml-2"
          :type="userInfoStore.currentWorkspace(workspaceId)?.accountPrivilege"
        />
      </Flex>
      <template
        v-if="
          acl.can(workspaceAddCompanyAcl) ||
            acl.can(workspaceApplyFeeChargingPlanAcl)
        "
      >
        <Flex align-center>
          <Modal
            v-model="isCompanyModalOpen"
            v-if="acl.can(AclRules.chargedPrivilege)"
          >
            <CreateCompany @closeModal="closeModal" />
            <template v-slot:activator="{ on }">
              <Button icon-name="plus" icon-sides="16" @click="on" class="ml-4">
                カンパニー新規作成
              </Button>
            </template>
          </Modal>
        </Flex>
      </template>
    </Flex>
  </Card>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserInfoStore } from '@/store/userInfo';
import { AclRules } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import { PRIVILEGE } from '@/common/constant';
import useModal from '@/composables/modal';
import CreateCompany from '@/pages/workspace/CreateCompany.vue';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import PrivilegeIcon from '@/components/ui/PrivilegeIcon.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Modal from '@/components/ui/Modal.vue';
import Typography from '@/components/ui/Typography.vue';
import {
  workspaceAddCompanyAcl,
  workspaceApplyFeeChargingPlanAcl
} from '@/composables/acls';

export default defineComponent({
  name: 'WorkspaceHeader',
  components: {
    AccountIcon,
    Button,
    Card,
    CreateCompany,
    Flex,
    Modal,
    PrivilegeIcon,
    Typography
  },
  setup() {
    const {
      params: { workspaceId }
    } = useRoute();
    const userInfoStore = useUserInfoStore();
    const { user } = storeToRefs(userInfoStore);
    const { isModalOpen: isCompanyModalOpen, closeModal } = useModal();
    const acl = useAcl();

    return {
      workspaceAddCompanyAcl,
      workspaceApplyFeeChargingPlanAcl,
      workspaceId,
      acl,
      PRIVILEGE,
      user,
      isCompanyModalOpen,
      closeModal,
      AclRules,
      userInfoStore
    };
  }
});
</script>
