
import { defineComponent, PropType } from 'vue';
import { debounce } from 'lodash';
import { useSearchCompany, useCompanyProducts } from '@/composables/workspace';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import GrayBack from '@/components/ui/GrayBack.vue';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Loading from '@/components/ui/Loading.vue';
import TextField from '@/components/ui/TextField.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'CreateCompanyStep1',
  components: {
    Box,
    ClickGuard,
    Flex,
    GrayBack,
    Icon,
    IconButton,
    Loading,
    TextField,
    Typography
  },
  props: {
    company: Object as PropType<{ id?: number; name: string }>
  },
  emits: ['select-company'],
  setup(props) {
    const {
      cmSponsorName,
      isSearching,
      searchResult,
      searchCmCompany,
      resetSearchResult
    } = useSearchCompany(props.company);

    const {
      products,
      isLoadingProducts,
      fetchProducts,
      resetProducts
    } = useCompanyProducts(props.company?.id?.toString());

    const debouncedInput = debounce((value: string) => {
      searchCmCompany(value);
    }, 300);

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      cmSponsorName.value = target.value;
      debouncedInput(target.value);
    };

    const handleInputFocus = () => {
      searchCmCompany(cmSponsorName.value);
    };

    return {
      cmSponsorName,
      isSearching,
      searchResult,
      searchCmCompany,
      resetSearchResult,
      products,
      isLoadingProducts,
      fetchProducts,
      resetProducts,
      debouncedInput,
      handleInput,
      handleInputFocus
    };
  }
});
